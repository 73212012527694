import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          fresques: "Frescoes",
          about: "About",
          contact: "Contact",
          artwork_titles: {
            "1": "January 2023"
          },
          artwork_descriptions: {
            "1": "The blue flower of joy, painted in gentle ultramarine and cyan tyrant, depicts unconditional love and the death of the body."
          },
          artwork_sizes: {
            "1": "59x59 inches"
          }
        }
      },
      fr: {
        translation: {
          fresques: "Fresques",
          about: "À propos",
          contact: "Contact",
          artwork_titles: {
            "1": "Janvier 2023"
          },
          artwork_descriptions: {
            "1": "La fleur bleue de joie peinte en outremer doux et cyan tyran dépeint l’amour inconditionnel et la mort du corps."
          },
          artwork_sizes: {
            "1": "150x150cm"
          }
        }
      },
      jp: {
        translation: {
          fresques: "壁画",
          about: "約",
          contact: "連絡先",
          artwork_titles: {
            "1": "2023年1月"
          },
          artwork_descriptions: {
            "1": "優しいウルトラマリンとシアンの暴君で描かれた喜びの青い花は、無条件の愛と肉体の死を描いています。"
          },
          artwork_sizes: {
            "1": "約4.95尺"
          }
        }
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
