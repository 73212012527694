import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { motion } from 'framer-motion';
import menuIcon from '../img/flowers.svg';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const Imieiamori = () => {
  const [imageLoaded, setImageLoaded] = useState(false);  // État pour suivre si l'image est chargée
  const navigate = useNavigate(); 

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh',  width: '100vw', overflow: 'hidden',cursor:'move'  }}>
        {!imageLoaded && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Box
              component="img"
              src={menuIcon}
              alt="Loading"
              sx={{
                width: '45px',
                height: '45px',
                animation: 'spin 6s linear infinite',
              }}
            />
            <style>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </Box>
        )}
        
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={0}
          defaultPositionY={0}
          wheel={{
            step: 0.5,
          }}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <TransformComponent>
                <img 
                  src="/assets/frescoes/color-large.jpg" 
                  alt="Large Colorful Monster" 
                  style={{ height: '100vh', display: imageLoaded ? 'block' : 'none' }} 
                  onLoad={() => setImageLoaded(true)}  // Gestionnaire onLoad pour mettre à jour l'état lorsque l'image est chargée
                />
              </TransformComponent>

              {imageLoaded && (
                <>
                  {/* Ajout du IconButton pour le retour */}
                  <IconButton
                    onClick={() => navigate('/frescoes')}
                    sx={{
                      position: 'fixed',
                      top: 10,
                      left: 10,
                      zIndex: 10,
                      color: '#000000',
                      borderRadius: '50%',
                      backgroundColor: '#FFF',
                      width: '48px',
                      height: '48px',
                      '&:hover': {
                        backgroundColor: '#ffffff',
                      },
                    }}
                  >
                    <ArrowBackOutlinedIcon sx={{ fontSize: '22px' }} />
                  </IconButton>

                  {/* Boutons de zoom */}
                  <Box position="absolute" bottom="15px" left="50%" zIndex="tooltip" sx={{ transform: 'translateX(-50%)', display: 'flex', gap: 1 }}>
                    <IconButton 
                      onClick={() => zoomOut()}
                      sx={{ 
                        backgroundColor: 'white', 
                        boxShadow: 'none', 
                        height: '38px', 
                        width: '38px',
                        color: 'black', // Icône noire
                        '&:hover': {
                          backgroundColor: 'white', // Assurer que le fond reste blanc au hover
                          boxShadow: 'none' // Aucune ombre au hover
                        }
                      }} 
                      aria-label="zoom-out"
                    >
                      <RemoveIcon fontSize="20px" />
                    </IconButton>
                    <IconButton 
                    onClick={() => zoomIn()}
                      sx={{ 
                        backgroundColor: 'white', 
                        boxShadow: 'none', 
                        height: '38px', 
                        width: '38px',
                        color: 'black', // Icône noire
                        '&:hover': {
                          backgroundColor: 'white', // Assurer que le fond reste blanc au hover
                          boxShadow: 'none' // Aucune ombre au hover
                        }
                      }} 
                      aria-label="zoom-in"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>

                </>
              )}
            </>
          )}
        </TransformWrapper>
      </Box>
    </motion.div>
  );
};

export default Imieiamori;
