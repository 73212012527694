import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ZoomArtwork from '../components/ZoomArtwork';
import Statement from '../components/Statement';
import NotFound from '../components/NotFound';
import menuIcon from '../img/flowers.svg';
import { useTranslation } from 'react-i18next'; // Importer le hook de traduction

const Artwork = () => {
  const { id } = useParams(); 
  const [artwork, setArtwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 
  const { t } = useTranslation(); // Utiliser le hook de traduction

  useEffect(() => {
    const fetchArtwork = async () => {
      try {
        const response = await fetch('/data/flowers.json');
        const data = await response.json();
        const foundArtwork = data.find((item) => item.id === parseInt(id));
        setArtwork(foundArtwork);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'œuvre', error);
        setLoading(false);
      }
    };

    fetchArtwork();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          component="img"
          src={menuIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  if (!artwork) {
    return <Box> <NotFound /> </Box>;
  }

  return (
    <Box>
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'fixed',
          top: 10,
          left: 10,
          zIndex: 10,
          color: '#000000',
          borderRadius: '50%',
          backgroundColor: '#FFF',
          width: '48px',
          height: '48px',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        }}
      >
        <ArrowBackOutlinedIcon sx={{ fontSize: '22px' }} />
      </IconButton>

      {/* Utiliser l'image lourde pour un meilleur rendu */}
      <ZoomArtwork imageSrc={`/assets/images/${artwork.heavyImage}`} /> 

      {/* Utiliser la traduction dynamique */}
      <Statement 
        title={t(`artwork_titles.${artwork.slug}`, { defaultValue: artwork.title })} 
        description={t(`artwork_descriptions.${artwork.slug}`, { defaultValue: artwork.description })} 
        size={t(`artwork_sizes.${artwork.slug}`, { defaultValue: artwork.size })} 
      />
    </Box>
  );
};

export default Artwork;
