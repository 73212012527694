import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AnimatePresence } from 'framer-motion';
import { I18nextProvider } from 'react-i18next'; // Fournisseur de traduction
import i18n from './i18n'; // Configuration i18next
import { useEffect } from 'react'; // Importer useEffect pour le préchargement des images
import theme from './theme';
import './App.css';

import Index from './views/Index';
import Flowers from './views/Flowers';
import About from './views/About';
import Frescoes from './views/Frescoes';
import Imieiamori from './views/Imieiamori';
import Littlemermaid from './views/Littlemermaid';

import Artwork from './views/Artwork'; 

function AppWithAnimatePresence() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Index />} />
        <Route path="/flowers" element={<Flowers />} />
        <Route path="/artwork/:id" element={<Artwork />} /> 
        <Route path="/about" element={<About />} />
        <Route path="/frescoes" element={<Frescoes />} />
        <Route path="/imieiamori" element={<Imieiamori />} />
        <Route path="/littlemermaid" element={<Littlemermaid />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  // Fonction pour précharger les images
  useEffect(() => {
    const preloadImages = async () => {
      try {
        const response = await fetch('/data/flowers.json'); // Charger le fichier flowers.json
        const data = await response.json();

        data.forEach(artwork => {
          // Précharger l'image principale
          const mainImg = new Image();
          mainImg.src = `/assets/images/${artwork.mainImage}`;

          // Précharger les images secondaires
          artwork.secondaryImages.forEach(image => {
            const secImg = new Image();
            secImg.src = `/assets/images/${image}`;
          });
        });
      } catch (error) {
        console.error("Erreur lors du préchargement des images :", error);
      }
    };

    preloadImages(); // Appeler la fonction pour précharger les images
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <AppWithAnimatePresence />
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
