import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/Header'; 
import ArtworkSlider from '../components/ArtworkSlider'; 
import { motion } from 'framer-motion'; // Importer motion pour l'animation

const Index = () => {
  return (
    <Box>
      <Header />
      
      {/* Ajouter un effet de fade-in pour ArtworkSlider */}
      <motion.div
        initial={{ opacity: 0 }}  // Commencer avec une opacité de 0
        animate={{ opacity: 1 }}   // L'animation passera à 1
        transition={{ duration: 1 }} // Transition d'une durée de 1 seconde
      >
        <ArtworkSlider />
      </motion.div>
    </Box>
  );
};

export default Index;
