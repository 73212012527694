import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Box, Grid, Link } from '@mui/material';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Header from '../components/Header';
import { motion } from 'framer-motion';
import Lenis from '@studio-freight/lenis';
import menuIcon from '../img/flowers.svg'; // Utilisez l'icône souhaitée pour le loader

const About = () => {
  const [data, setData] = useState(null);
  const lenisRef = useRef(null);

  // Fonction pour diviser la biographie en paragraphes
  const formatBiography = (biography) => {
    return biography.split('\n\n').map((paragraph, index) => (
      <Typography key={index} variant="body1" paragraph>
        {paragraph}
      </Typography>
    ));
  };

  // Initialisation de Lenis pour un défilement fluide
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });
    lenisRef.current = lenis;
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    return () => lenis.destroy();
  }, []);

  useEffect(() => {
    fetch('/data/about.json')
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData));
  }, []);

  // Variantes d'animation pour le fade-in
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  // Variantes d'animation pour le reste (expositions, prix, etc.)
  const blockVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  if (!data) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          component="img"
          src={menuIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Container sx={{ pt: { xs: 7, md: 10 }, pb: 10, px: { xs: 5, md: 15 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            {/* Animation fade-in pour la photo */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}  // Fade-in
              variants={fadeInVariants}
            >
              <Box>
                <img
                  src="/assets/images/portrait.jpg"
                  alt="Kajika Aki Ferrazzini portrait"
                  style={{ width: '100%', borderRadius: '4px' }}
                />
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={8}>
            {/* Animation fade-in pour la biographie */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}  // Fade-in
              variants={fadeInVariants}
            >
              <Box sx={{ lineHeight: '1.8' }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Biographie
                </Typography>
                {formatBiography(data.biography)}
              </Box>
            </motion.div>
          </Grid>
        </Grid>

        {/* Le reste du contenu garde l'animation d'apparition avec un décalage (expositions, prix, presse) */}
        <Box mt={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            Expositions
          </Typography>
          {data.exhibitions.map((exhibition, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}  // Décalage en Y
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.exhibitions.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {exhibition.dates}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {exhibition.title}
                  </Typography>
                  <Typography variant="body2">
                    {exhibition.organized_by} / {exhibition.city}, {exhibition.venue}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" align="right">
                    {exhibition.type}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Prix
          </Typography>
          {data.awards.map((award, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}  // Décalage en Y
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.awards.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.year}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>{award.event}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography textTransform={'uppercase'} variant="body2">
                    {award.title}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Presse
          </Typography>
          {data.press.map((article, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}  // Décalage en Y
              variants={blockVariants}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: index === data.press.length - 1 ? '1px solid #ccc' : 'none',
                  paddingY: 2,
                }}
              >
                <Grid item xs={2}>
                  <Typography textTransform={'uppercase'} variant="body2">
                    {article.date}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {article.title}
                  </Typography>
                  <Typography variant="body2">
                    {article.source}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right">
                  <Link href={article.url} target="_blank" rel="noopener">
                    <ArrowOutwardOutlinedIcon sx={{ color: 'black' }} fontSize="small" />
                  </Link>
                </Grid>
              </Grid>
            </motion.div>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default About;
