import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitType from '../js/split-type.min';
import Lenis from '@studio-freight/lenis';

export default function Statement({ title, description, size }) { // Recevoir title, description, et size
  const textRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const textSplitter = new SplitType(textRef.current, { types: 'words, chars' });

    gsap.fromTo(
      textSplitter.chars,
      { filter: 'blur(10px)', opacity: 0, y: 20 },
      {
        filter: 'blur(0px)',
        opacity: 1,
        y: 0,
        stagger: 0.05,
        scrollTrigger: {
          trigger: textRef.current,
          start: 'top 30%', // Commence l'animation plus tôt
          end: 'bottom 100%', // Laisse plus de temps pour terminer
          scrub: true, // Animation fluide
        },
      }
    );

    // Initialiser Lenis pour un défilement fluide
    const lenis = new Lenis({
      duration: 1.2, // Lissage du défilement
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy(); // Nettoyer Lenis lors du démontage du composant
    };
  }, []);

  return (
    <Box
      sx={{
        minHeight: '400vh',
        textAlign: 'left',
        position: 'relative', // Utilisation de relative pour permettre le positionnement du dégradé
        width: '100%',
      }}
    >
      {/* Ajout des dégradés en haut et en bas */}
      <Box
        sx={{
          width: '100%',
          height: '60vh',
          background: 'linear-gradient(to top, #ffffff, rgba(255,255,255,0))',
          zIndex: 1,
        }}
      />
      <Box
        ref={textRef}
        sx={{
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'center',
          fontFamily: 'Ranade, sans-serif',
          lineHeight: '2',
          width: '100%',
          margin: '0 auto',
          pt: 12,
          pb: 4,
          minHeight:'110vh',
        }}
      >
        {/* Description de l'œuvre */}
        <Typography sx={{ px: { xs: 5, md: 10,  }, maxWidth: '800px', fontFamily: 'Manrope, sans-serif', mb: '3vh', lineHeight: '1.4em' }} variant="h4">
          {description}
        </Typography>
        {/* Date et taille de l'œuvre */}
        <Typography sx={{ px: { xs: 5, md: 10,  }, maxWidth: '800px', fontFamily: 'Manrope, sans-serif', }} variant="body">
          {title} - {size}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '60vh',
          background: 'linear-gradient(to bottom, #ffffff, rgba(255,255,255,0))',
          zIndex: 1,
        }}
      />
    </Box>
  );
}
