// src/views/Flowers.js
import React from 'react';
import { Box } from '@mui/material';
import Statement from '../components/Statement'; 

const Flowers = () => {
  return (
    <Box>
        <Statement />
    </Box>
  );
};

export default Flowers;
