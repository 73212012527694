import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Utilisé pour la navigation
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import menuIcon from '../img/flowers.svg'; // Assurez-vous d'avoir une image pour le loader

const Frescoes = () => {
  const navigate = useNavigate(); // Hook pour la navigation
  const [loading, setLoading] = useState(true); // Suivi de l'état du chargement

  useEffect(() => {
    // Simuler un délai de chargement des images
    const loadImages = setTimeout(() => {
      setLoading(false); // Après un délai, on considère que les images sont chargées
    }, 2000); // Simuler 2 secondes de chargement

    return () => clearTimeout(loadImages); // Nettoyer le timeout si le composant est démonté
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Box
          component="img"
          src={menuIcon}
          alt="Loading"
          sx={{
            width: '45px',
            height: '45px',
            animation: 'spin 6s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA - Fresques</title>
        <meta name="description" content="Dive into the heart of my two giant frescoes." />
      </Helmet>

      {/* Afficher le header après le chargement */}
      <Header />

      {/* Conteneur scindé en deux pour les fresques */}
      <Box 
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Colonne en mobile, row en desktop
          height: '100vh', 
          width: '100vw',
        }}
      >
        {/* Section gauche - The life and death of the little mermaid */}
        <Box
          onClick={() => {
            navigate('/littlemermaid');
          }}
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            src="/assets/frescoes/bw-thumbnail.jpg"
            alt="The life and death of the little mermaid"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              color: '#000',
              backgroundColor: '#FFF',
              padding: '3% 5%',
              borderRadius: 1,
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography mb={0.5} variant="h5">The life and death of the little mermaid</Typography>
            <Typography variant="subtitle1">1.5x10m, Ink on Paper</Typography>
          </Box>
        </Box>

        {/* Section droite - Imieiamori */}
        <Box
          onClick={() => {
            navigate('/imieiamori');
          }}
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: '50%', md: '100%' },
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            src="/assets/frescoes/color-thumbnail.jpg"
            alt="Imieiamori"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              color: '#000',
              backgroundColor: '#FFF',
              padding: '3% 5%',
              borderRadius: 1,
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography mb={0.5} variant="h5">Imieiamori</Typography>
            <Typography variant="subtitle1">1.5x10m, Ink and Watercolor on Paper</Typography>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default Frescoes;
